<template>
  <v-container fluid class="ma-0 pa-0" :class="{'light-background': $vuetify.breakpoint.xsOnly, 'white': $vuetify.breakpoint.smAndUp}">
    <v-layout row wrap :pa-5="$vuetify.breakpoint.smAndUp" :pa-3="$vuetify.breakpoint.xsOnly">
      <v-flex xs12 max-width-1400 full-width ma-auto px-2 mb-5>
        <h1
          class="primary--text graphik-bold mb-2 mt-0"
          :class="{'font-35': $vuetify.breakpoint.smAndUp, 'font-25': $vuetify.breakpoint.xsOnly}"
        >{{ $ml.get('sinisters_title') }}</h1>
        <p
          class="dark-grey--text graphik-light mb-5"
          :class="{'font-20': $vuetify.breakpoint.smAndUp, 'font-15': $vuetify.breakpoint.xsOnly}"
        >{{ $ml.get('sinisters_description') }}</p>

        <v-container fluid ma-0 pa-0 grid-list-xl>
          <v-layout v-for="(step, index) in companies" :key="index" :wrap="$vuetify.breakpoint.xsOnly">
            <v-flex sm4 md3 lg2>
              <v-card flat tile color="light-background">
                <v-img
                  width="180"
                  max-height="100"
                  contain
                  :class="{'ma-auto': $vuetify.breakpoint.smAndUp}"
                  :src="step.image"
                  :lazy-src="step.image"
                ></v-img>
              </v-card>
            </v-flex>

            <v-flex sm10 md9 lg10>
              <v-card flat tile color="light-background" height="100%">
                <v-card-text :class="{'px-0': $vuetify.breakpoint.xsOnly}">
                  <v-layout row wrap>
                    <v-flex xs12 v-for="(phone, idx) in step.phones" :key="index + 's' + idx">
                      <p class="dark-grey--text graphik-medium font-15 mb-0">{{ phone.title }}</p>

                      <p
                        v-for="(p, i) in phone.text" :key="index + 's' + idx + 'p' + i"
                        class="dark-grey--text graphik-light font-15 mb-0"
                      >{{ p }}</p>
                    </v-flex>
                  </v-layout>

                  <v-layout row wrap>
                    <v-flex xs12>
                      <p class="primary--text graphik-medium font-15 mb-0" v-if="step.app">{{ $ml.get('sinisters_app') }} {{ step.app }}</p>
                    </v-flex>
                  </v-layout>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'Sinisters',
  metaInfo: {
    title: process.env.VUE_APP_METATAGS_TITLE_SINISTERS,
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: process.env.VUE_APP_METATAGS_DESCRIPTION_SINISTERS
      },
      {
        vmid: 'og:title',
        property: 'og:title',
        content: process.env.VUE_APP_METATAGS_TITLE_SINISTERS
      },
      {
        vmid: 'og:description',
        property: 'og:description',
        content: process.env.VUE_APP_METATAGS_DESCRIPTION_SINISTERS
      },
      {
        vmid: 'twitter:title',
        property: 'twitter:title',
        content: process.env.VUE_APP_METATAGS_TITLE_SINISTERS
      },
      {
        vmid: 'twitter:description',
        property: 'twitter:description',
        content: process.env.VUE_APP_METATAGS_DESCRIPTION_SINISTERS
      },
      {
        vmid: 'og:url',
        property: 'og:url',
        content: process.env.VUE_APP_METATAGS_URL + '/siniestros'
      },
    ],
    link: [
      // SEO
      {
        vmid: 'canonical',
        rel: 'canonical',
        href: process.env.VUE_APP_METATAGS_URL + '/siniestros'
      }
    ]
  },
  data () {
    return {
      companies: {
        qualitas: {
          image: require('@/assets/img/companies/qualitas-full.png'),
          app: 'Q Movil',
          phones: [
            {
              title: 'Qualitas',
              text: ['01 800 288 6700', '01 800 800 2880']
            }
          ]
        },
        gnp: {
          image: require('@/assets/img/companies/gnp-full.png'),
          app: 'GNP Movil',
          phones: [
            {
              title: 'GNP',
              text: ['01 800 400 9000']
            }
          ]
        },
        chubb: {
          image: require('@/assets/img/companies/chubb-full.png'),
          app: 'ABA Clientes',
          phones: [
            {
              title: 'Aba | Chubb',
              text: []
            },
            {
              title: 'Auto',
              text: ['Cd. de México, Monterrey y Guadalajara', 'Teléfono: 1253 3030', 'Resto del país: 01 800 834 3400']
            },
            {
              title: 'Hogar',
              text: ['Reporte de Siniestro: Servicio a Clientes:', 'Cd. de México, Monterrey y Guadalajara', 'Teléfono: 1253 3737', 'Resto del país: 01 800 716 5467']
            }
          ]
        },
        hdi: {
          image: require('@/assets/img/companies/hdi-full.png'),
          app: 'HDI Contigo',
          phones: [
            {
              title: 'HDI Seguros',
              text: ['01 800 019 6000']
            }
          ]
        },
        ana: {
          image: require('@/assets/img/companies/ana-full.png'),
          app: 'ANA GO',
          phones: [
            {
              title: 'ANA Seguros',
              text: ['Cd. De México 5322 8282', 'Nacional 01 800 911 2627', 'Internacional 001 52 55 5322 8282']
            }
          ]
        },
        afirme: {
          image: require('@/assets/img/companies/afirme-full.png'),
          app: 'Afirme Seguros',
          phones: [
            {
              title: 'AFIRME Seguros',
              text: ['Siniestros:  01 800 723 4763', 'Atención a Clientes: 01 800 734 8761']
            }
          ]
        },
        mapfre: {
          image: require('@/assets/img/companies/mapfre-full.png'),
          app: 'Mapfre me ayuda',
          phones: [
            {
              title: 'Mapfre',
              text: []
            },
            {
              title: 'Auto',
              text: ['01 800 062 7373']
            },
            {
              title: 'Gastos Médicos Mayores',
              text: ['Cd. México 01 55 5246 7502', 'Nacional 01 800 365 0024']
            }
          ]
        },
        primeroSeguros: {
          image: require('@/assets/img/companies/primeroSeguros-full.png'),
          app: '',
          phones: [
            {
              title: 'Primero Seguros',
              text: ['01 899 917 6288']
            }
          ]
        },
        axa: {
          image: require('@/assets/img/companies/axa-full.png'),
          app: 'My AXA México',
          phones: [
            {
              title: 'AXA Seguros',
              text: ['01 800 911 1292']
            }
          ]
        }
      }
    }
  }
}
</script>

<style scoped>
</style>
